import React, { useRef, useState } from "react";
import "./LandingPagePopup.css";
import images from "../../assets/images";
import DropdownList from "../../common/components/dropdown/DropdownList";
import axios from "axios";
import { toast } from "react-toastify";
import ScheduleGrid from "./CustomCalender";
import { element } from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
export default function LandingPagePopup({ handleOpen, isOpen, setIsOpen }) {
  // hooks
  const [adultsArray, setAdultsArray] = useState([{ name: "" }]);
  const [childrenArray, setChildrenArray] = useState([{ name: "", age: "" }]);
  const [helpersArray, setHelpersArray] = useState([{ name: "" }]);
  const [loading, setLoading] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  // const [isOpen, setIsOpen] = useState(true);
  const [title, setTitle] = useState("Size of House");
  const [listItems, setListItems] = useState([
    { id: 0, item1: "Size of House" },
    { id: 1, item1: "0 - 750 sq ft" },
    { id: 2, item1: "700 - 1500 sq ft" },
    { id: 3, item1: "1500sq ft+" },
  ]);
  const [email, setEmail] = useState("");
  const [averageTime, setAverageTime] = useState(0);
  const [events, setEvents] = useState([]);
  const [rightItemsCount, setRightItemsCount] = useState({
    dogs: 0,
    cats: 0,
    others: 0,
    plants: 0,
    bedroom: 0,
    bathroom: 0,
    dining: 0,
    living: 0,
    kitchen: 0,
    cars: 0,
  });
  const [activeComponent, setActiveComponent] = useState(1);
  // handlers
  // const handleOpen = () => {
  //   setIsOpen(true);
  // };

  const recaptchaRef = useRef();

  const handleAdultsOperation = (operation, name, index) => {
    if (operation === "add") {
      setAdultsArray([...adultsArray, { name }]);
    } else if (operation === "update") {
      let temp = [...adultsArray];
      temp[index] = { name };
      setAdultsArray(temp);
    } else if (operation === "remove") {
      let temp = [...adultsArray];
      temp.splice(index, 1);
      setAdultsArray(temp);
    } else {
      let temp = [...adultsArray];
      temp.pop();
      setAdultsArray(temp);
    }
  };

  const handleRecaptchaSuccess = (response) => {
    setRecaptchaResponse(response);
  };
  const handleSubmit = (event) => {
    if (!isValidEmail(email)) {
      toast.warning("please provide email");
      return;
    }
    if (!recaptchaResponse || recaptchaResponse.trim() === "") {
      toast.warning("Please solve the captcha first");
      return;
    }
    if (!isTermsAccepted) {
      toast.warning("Please accept the terms of service and privacy first");
      return;
    }
    setLoading(true);
    let formData = {
      email: email,
      recaptcha_response: recaptchaResponse,
      home_details: {
        bathroom: {
          count: rightItemsCount.bathroom,
        },
        cars: {
          count: rightItemsCount.cars,
        },
        kitchen: {
          count: rightItemsCount.kitchen,
        },

        living_room: {
          count: rightItemsCount.living,
        },
        pets: {
          count:
            rightItemsCount.dogs +
            rightItemsCount.cats +
            rightItemsCount.others,
        },
        home_plants: {
          count: rightItemsCount.plants,
        },
      },
      family_members: {
        audlt_details: adultsArray
          .map((details) => details.name)
          .filter((name) => name != ""),
        children_detail: childrenArray.filter(
          (obj) => obj.name != "" && Number(obj.age)
        ),
        helper_details: helpersArray
          .map((details) => details.name)
          .filter((name) => name != ""),
      },
    };
    if (event) {
      event.preventDefault();
    }
    axios
      .post("https://famplan.co/mvp-api/api/task_management/schedule", formData)
      .then((response) => {
        let data = response.data.data.dateWiseSchedule;
        let formatedData = [];
        let id = 0;
        // Object.values(data).forEach((dateWiseTask) => {
        //   console.log("dateWiseTask::", dateWiseTask);
        //   dateWiseTask.forEach((task,index) => {
        //     if(index==0){
        //       console.log("task.start::",task.start,new Date(task.start))
        //     }
        //     let startTime = new Date(task.start) ;
        //     startTime.setHours(startTime.getHours() - 5)
        //     startTime.setMinutes(startTime.getMinutes() - 30);

        //     let endTime = new Date(task.end) ;
        //     endTime.setHours(endTime.getHours() - 5)
        //     endTime.setMinutes(endTime.getMinutes() - 30);

        //     formatedData.push({
        //       id,
        //       title: task.title,
        //       start: startTime,
        //       end: endTime,
        //     });
        //     id++;
        //   });
        // });
        setAverageTime(Math.round(response.data.data.averageMinPerDay / 60));
        setEvents({ ...data });
        handelActiveComponent(activeComponent + 1);
        setLoading(false);
      })
      .catch((error) => console.error("Error submitting data:", error));
  };

  const handleRightItems = (operation, key) => {
    let temp = { ...rightItemsCount };
    if (operation === "increment") {
      temp[key] += 1;
    } else if (temp[key] > 0) {
      temp[key] -= 1;
    }
    setRightItemsCount(temp);
  };

  const handleHelpersOperation = (operation, name, index) => {
    if (operation === "add") {
      setHelpersArray([...helpersArray, { name }]);
    } else if (operation === "update") {
      let temp = [...helpersArray];
      temp[index] = { name };
      setHelpersArray(temp);
    } else if (operation === "remove") {
      let temp = [...helpersArray];
      temp.splice(index, 1);
      setHelpersArray(temp);
    } else {
      let temp = [...helpersArray];
      temp.pop();
      setHelpersArray(temp);
    }
  };

  const handleChildrenOperation = (operation, name, age, index) => {
    if (operation === "add") {
      setChildrenArray([...childrenArray, { name, age }]);
    } else if (operation === "update") {
      let temp = [...childrenArray];
      temp[index].name = name;
      if (Number(age)) {
        temp[index].age = age;
      }
      setChildrenArray(temp);
    } else if (operation === "remove") {
      let temp = [...childrenArray];
      temp.splice(index, 1);
      setChildrenArray(temp);
    } else {
      let temp = [...childrenArray];
      temp.pop();
      setChildrenArray(temp);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setRightItemsCount({
      dogs: 0,
      cats: 0,
      others: 0,
      plants: 0,
      bedroom: 0,
      bathroom: 0,
      dining: 0,
      living: 0,
      kitchen: 0,
      cars: 0,
    });
    setActiveComponent(1);
    setEvents([]);
    setTitle("Size of House");
    setAdultsArray([{ name: "" }]);
    setChildrenArray([{ name: "", age: "" }]);
    setHelpersArray([{ name: "" }]);
    setEmail("");
    setRecaptchaResponse("");
  };

  const handleIsTermsAccepted = () => {
    setIsTermsAccepted((prevState) => !prevState);
  };

  const handelActiveComponent = (component) => {
    if (component === 2) {
      let tempHelper = [...helpersArray];
      let tempChildern = [...childrenArray];
      tempChildern.map((element, index) => {
        element.name = `child_${index + 1}`;
      });
      tempHelper.map((element, index) => {
        element.name = `helper_${index + 1}`;
      });
      setChildrenArray(tempChildern);
      setHelpersArray(tempHelper);
    }
    if (component === 3) {
      recaptchaRef.current.reset();
      if (title === "Size of House") {
        toast.warning("Please select size of house !");
        return;
      }
    }
    setActiveComponent(component);
  };

  const handleSelect = (item) => {
    setTitle(item.item1);
    let temp = { ...rightItemsCount };
    if (item.id === 0) {
      temp.bedroom = 0;
      temp.bathroom = 0;
      temp.dining = 0;
      temp.living = 0;
      temp.kitchen = 0;
      temp.cars = 0;
    } else if (item.id === 1) {
      temp.bedroom = 1;
      temp.bathroom = 1;
      temp.dining = 0;
      temp.living = 1;
      temp.kitchen = 1;
      temp.cars = 0;
    } else if (item.id === 2) {
      temp.bedroom = 2;
      temp.bathroom = 1;
      temp.dining = 1;
      temp.living = 1;
      temp.kitchen = 1;
      temp.cars = 0;
    } else {
      temp.bedroom = 3;
      temp.bathroom = 2;
      temp.dining = 1;
      temp.living = 1;
      temp.kitchen = 1;
      temp.cars = 0;
    }
    setRightItemsCount(temp);
  };
  return (
    <div>
      {isOpen && (
        <div className="overlay">
          <div className="popup">
            <img
              src={images.closeModal}
              className="close-btn"
              alt=""
              onClick={handleClose}
            />
            <img
              src={images.modalBack}
              className="back-btn"
              alt=""
              style={{
                visibility: `${activeComponent > 1 ? "visible" : "hidden"}`,
              }}
              onClick={() => {
                handelActiveComponent(activeComponent - 1);
              }}
            />
            <form action="/" method="post">
              <div
                className="house-composition-p1"
                style={{
                  display: `${activeComponent === 1 ? "flex" : "none"}`,
                }}>
                <div className="heading">
                  <h3> House composition</h3>
                </div>
                <div className="content">
                  <div className="left">
                    <div className="q1">
                      <div className="top">
                        <div className="top-left">
                          <h4>
                            How many adult in the household(excl. helpers)?
                          </h4>
                        </div>
                        <div className="top-right">
                          <div
                            className={`btn-bg ${
                              adultsArray.length ? "" : "btn-zero"
                            }`}
                            onClick={() => {
                              handleAdultsOperation("substract", "");
                            }}>
                            <img src={images.minus} alt="" />
                          </div>
                          <h4>{adultsArray.length}</h4>
                          <div
                            className="btn-bg"
                            onClick={() => {
                              handleAdultsOperation("add", "");
                            }}>
                            <img src={images.plus} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="buttom">
                        {adultsArray.map((detailObj, index) => {
                          return (
                            <div key={index} className="ans-q1">
                              <div className="cross">
                                <img
                                  src={images.closeOption}
                                  alt=""
                                  onClick={() => {
                                    handleAdultsOperation("remove", "", index);
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                value={detailObj.name}
                                placeholder={"Member name"}
                                onChange={(e) => {
                                  handleAdultsOperation(
                                    "update",
                                    e.target.value,
                                    index
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="q2">
                      <div className="top">
                        <div className="top-left">
                          <h4>How many children?</h4>
                        </div>
                        <div className="top-right">
                          <div
                            className={`btn-bg ${
                              childrenArray.length ? "" : "btn-zero"
                            }`}
                            onClick={() => {
                              handleChildrenOperation("substract", "", "");
                            }}>
                            <img src={images.minus} alt="" />
                          </div>
                          <h4>{childrenArray.length}</h4>
                          <div
                            className="btn-bg"
                            onClick={() => {
                              handleChildrenOperation("add", "", "");
                            }}>
                            <img src={images.plus} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="buttom">
                        {childrenArray.map((detailObj, index) => {
                          return (
                            <div key={index} className="ans-q1">
                              <div
                                className="cross"
                                onClick={() => {
                                  handleChildrenOperation(
                                    "remove",
                                    "",
                                    "",
                                    index
                                  );
                                }}>
                                <img src={images.closeOption} alt="" />
                              </div>
                              {/* <input
                                className="child-name"
                                type="text"
                                placeholder="Name"
                                value={childrenArray[index].name}
                                onChange={(e) => {
                                  handleChildrenOperation(
                                    "update",
                                    e.target.value,
                                    childrenArray[index].age,
                                    index
                                  );
                                }}
                              /> */}
                              <input
                                className="child-age"
                                type="number"
                                placeholder={`Child ${index + 1} age`}
                                value={childrenArray[index].age}
                                onChange={(e) => {
                                  handleChildrenOperation(
                                    "update",
                                    childrenArray[index].name,
                                    e.target.value,
                                    index
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="q1">
                      <div className="top">
                        <div className="top-left">
                          <h4>How many helpers?</h4>
                        </div>
                        <div className="top-right">
                          <div
                            className={`btn-bg ${
                              helpersArray.length ? "" : "btn-zero"
                            }`}
                            onClick={() => {
                              handleHelpersOperation("substract", "");
                            }}>
                            <img src={images.minus} alt="" />
                          </div>
                          <h4>{helpersArray.length}</h4>
                          <div
                            className="btn-bg"
                            onClick={() => {
                              handleHelpersOperation("add", "");
                            }}>
                            <img src={images.plus} alt="" />
                          </div>
                        </div>
                      </div>
                      {/* <div className="buttom">
                        {helpersArray.map((detailObj, index) => {
                          return (
                            <div key={index} className="ans-q1">
                              <div className="cross">
                                <img
                                  src={images.closeOption}
                                  alt=""
                                  onClick={() => {
                                    handleHelpersOperation("remove", "", index);
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                value={detailObj.name}
                                placeholder={"Helper name"}
                                onChange={(e) => {
                                  handleHelpersOperation(
                                    "update",
                                    e.target.value,
                                    index
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </div> */}
                    </div>
                  </div>
                  <div className="right">
                    <p>PETS</p>
                    <div className="right-item">
                      <div className="right-left">
                        <h4>Dogs?</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg ${
                            rightItemsCount && rightItemsCount.dogs
                              ? ""
                              : "btn-zero"
                          }`}
                          onClick={() => {
                            handleRightItems("decrement", "dogs");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.dogs
                            ? rightItemsCount.dogs
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "dogs");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="right-item">
                      <div className="right-left">
                        <h4>Cats?</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg ${
                            rightItemsCount && rightItemsCount.cats
                              ? ""
                              : "btn-zero"
                          }`}
                          onClick={() => {
                            handleRightItems("decrement", "cats");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.cats
                            ? rightItemsCount.cats
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "cats");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="right-item">
                      <div className="right-left">
                        <h4>Others?</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg ${
                            rightItemsCount && rightItemsCount.others
                              ? ""
                              : "btn-zero"
                          }`}
                          onClick={() => {
                            handleRightItems("decrement", "others");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.others
                            ? rightItemsCount.others
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "others");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="right-item" style={{ marginTop: "2rem" }}>
                      <div className="right-left">
                        <h4>How many plants?</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg ${
                            rightItemsCount && rightItemsCount.plants
                              ? ""
                              : "btn-zero"
                          }`}
                          onClick={() => {
                            handleRightItems("decrement", "plants");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.plants
                            ? rightItemsCount.plants
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "plants");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="house-composition-p2"
                style={{
                  display: `${activeComponent === 2 ? "flex" : "none"}`,
                }}>
                <div className="heading">
                  <h3> House composition</h3>
                </div>
                <div className="content">
                  <div className="left">
                    <DropdownList
                      title={title}
                      listItems={listItems}
                      handelOnClick={handleSelect}
                    />
                  </div>
                  <div className="right">
                    <div className="right-item">
                      <div className="right-left">
                        <h4>Bedroom</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg`}
                          style={{
                            background: `${
                              rightItemsCount.bedroom ? "#4b4efc" : "#aeb1ff"
                            }`,
                          }}
                          onClick={() => {
                            handleRightItems("decrement", "bedroom");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.bedroom
                            ? rightItemsCount.bedroom
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "bedroom");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="right-item">
                      <div className="right-left">
                        <h4>Bathroom</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg`}
                          style={{
                            background: `${
                              rightItemsCount.bathroom ? "#4b4efc" : "#aeb1ff"
                            }`,
                          }}
                          onClick={() => {
                            handleRightItems("decrement", "bathroom");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.bathroom
                            ? rightItemsCount.bathroom
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "bathroom");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="right-item">
                      <div className="right-left">
                        <h4>Dining</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg`}
                          style={{
                            background: `${
                              rightItemsCount.dining ? "#4b4efc" : "#aeb1ff"
                            }`,
                          }}
                          onClick={() => {
                            handleRightItems("decrement", "dining");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.dining
                            ? rightItemsCount.dining
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "dining");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="right-item">
                      <div className="right-left">
                        <h4>Living</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg`}
                          style={{
                            background: `${
                              rightItemsCount.living ? "#4b4efc" : "#aeb1ff"
                            }`,
                          }}
                          onClick={() => {
                            handleRightItems("decrement", "living");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.living
                            ? rightItemsCount.living
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "living");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="right-item">
                      <div className="right-left">
                        <h4>Kitchen</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg `}
                          style={{
                            background: `${
                              rightItemsCount.kitchen ? "#4b4efc" : "#aeb1ff"
                            }`,
                          }}
                          onClick={() => {
                            handleRightItems("decrement", "kitchen");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.kitchen
                            ? rightItemsCount.kitchen
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "kitchen");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="right-item">
                      <div className="right-left">
                        <h4>How many cars?</h4>
                      </div>
                      <div className="right-right">
                        <div
                          className={`btn-bg`}
                          style={{
                            background: `${
                              rightItemsCount.cars ? "#4b4efc" : "#aeb1ff"
                            }`,
                          }}
                          onClick={() => {
                            handleRightItems("decrement", "cars");
                          }}>
                          <img src={images.minus} alt="" />
                        </div>
                        <h4>
                          {rightItemsCount && rightItemsCount.cars
                            ? rightItemsCount.cars
                            : 0}
                        </h4>
                        <div
                          className="btn-bg"
                          onClick={() => {
                            handleRightItems("increment", "cars");
                          }}>
                          <img src={images.plus} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="house-composition-p3"
                style={{
                  display: `${activeComponent === 3 ? "flex" : "none"}`,
                }}>
                <div className="img-con">
                  <img src={images.group26649} alt="" />
                </div>
                <div className="content">
                  <h2>Stay tuned!</h2>
                  <p>
                    We are still perfecting our app for parents! We’ll invite
                    you to beta testing if you leave your email below
                  </p>
                </div>
                <div className="mail-box">
                  <input
                    type="email"
                    placeholder="Enter Email"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />

                  {loading ? (
                    <div id="next-btn" style={{ cursor: "wait" }}>
                      <div className="typing-indicator">
                        <div className="typing-circle"></div>
                        <div className="typing-circle"></div>
                        <div className="typing-circle"></div>
                        <div className="typing-shadow"></div>
                        <div className="typing-shadow"></div>
                        <div className="typing-shadow"></div>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="next-btn"
                      onClick={() => {
                        handleSubmit();
                      }}>
                      Next
                    </div>
                  )}
                </div>
                <ReCAPTCHA
                  sitekey="6LeQ4ZonAAAAAMVVuDR-jkYeu7N11mlYA51EVsmH"
                  onChange={handleRecaptchaSuccess}
                  ref={recaptchaRef}
                />
                <div className="terms-check-cont">
                  <input
                    className="terms-check"
                    type="checkbox"
                    checked={isTermsAccepted}
                    onChange={handleIsTermsAccepted}
                  />
                  <label>
                    By clicking next, you agree to our{" "}
                    <a href="/termsOfService">terms of service and privacy</a>
                  </label>
                </div>
              </div>
              <div
                className="house-composition-p4"
                style={{
                  display: `${activeComponent === 4 ? "flex" : "none"}`,
                }}>
                <div className="con">
                  <div className="heading">Total Chore Hours</div>
                  <h5>
                    Based on your household composition and home, this is the
                    time you need to do house chores weekly.
                  </h5>
                  <div className="btn-cont">
                    <div className="content">
                      <div id="ellipse14">
                        <img src={images.ellipse14} alt="" />
                      </div>
                      <div id="ellipse15">
                        <img src={images.ellipse15} alt="" />
                      </div>

                      <h3>{averageTime} Hours</h3>
                      <p>
                        We generate the hours based on your needs, household
                        composition, and your home size. You can take a look for
                        what we’ve prepared for you.
                      </p>
                      <button
                        className="schedule-btn"
                        type="button"
                        onClick={(e) => {
                          handelActiveComponent(activeComponent + 1);
                        }}>
                        See schedule
                        <img src={images.arrowLeft} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="house-composition-p5"
                style={{
                  display: `${activeComponent === 5 ? "flex" : "none"}`,
                }}>
                <div className="heading">
                  <h2>Chore schedule</h2>
                  <h4>
                    You may share this with your new or existing helper to speed
                    up training and onboarding.
                  </h4>
                </div>
                <div
                  className="chart"
                  style={{ width: "100%", overflow: "auto" }}>
                  <ScheduleGrid events={events} />
                </div>
              </div>
              <div className="hide-show">
                <div
                  className="modal-end"
                  style={{
                    display: `${
                      activeComponent === 3 || activeComponent === 4
                        ? "none"
                        : "flex"
                    }`,
                  }}>
                  <div
                    className="modal-back-btn"
                    style={{
                      visibility: `${
                        activeComponent > 1 ? "visible" : "hidden"
                      }`,
                    }}
                    onClick={() => {
                      handelActiveComponent(activeComponent - 1);
                    }}>
                    Back
                  </div>
                  <div
                    className="modal-end-btn"
                    onClick={() => {
                      if (activeComponent === 5) {
                        handleClose();
                      } else {
                        handelActiveComponent(activeComponent + 1);
                      }
                    }}>
                    {activeComponent === 5 ? "Finish" : "Next"}
                  </div>
                </div>
              </div>
              <div className="hide-show-phone">
                <div
                  className="modal-end"
                  style={{
                    display: `${
                      activeComponent === 3 || activeComponent === 4
                        ? "none"
                        : "flex"
                    }`,
                  }}>
                  <div
                    className="modal-back-btn"
                    style={{
                      display: `${activeComponent > 1 ? "flex" : "none"}`,
                    }}
                    onClick={() => {
                      handelActiveComponent(activeComponent - 1);
                    }}>
                    Back
                  </div>
                  <div
                    className="modal-end-btn"
                    onClick={() => {
                      if (activeComponent === 5) {
                        handleClose();
                      } else {
                        handelActiveComponent(activeComponent + 1);
                      }
                    }}
                    style={{
                      width: `${activeComponent == 1 ? "100%" : "40%"}`,
                    }}>
                    {activeComponent === 5 ? "Finish" : "Next"}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
// style={{display:'none'}}
// <form onSubmit={handleSubmit}>
//             {/* Your form fields go here */}
//             <input type="text" name="name" placeholder="Name" />
//             <input type="email" name="email" placeholder="Email" />
//             <button type="submit">Submit</button>
//           </form>

// <select id="custom-select" class="custom-select">
//                     <option value="option1">Option 1</option>
//                     <option value="option2">Option 2</option>
//                     <option value="option3">Option 3</option>
//                   </select>
//                   <div className="chevron-down">
//                       <img src={images.chevronDown} alt="" />
//                   </div>

const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
