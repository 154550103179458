import "./popup.css";
import { createPortal } from 'react-dom';
import img from "../../../assets/images";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const Popup = ({ open, closeModel }) => {
  const [email, setEmail] = useState("");
  const recaptchaRef = useRef();
  const [recaptchaResponse, setRecaptchaResponse] = useState("");

  if (!open) return null

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleRecaptchaSuccess = (response) => {
    setRecaptchaResponse(response);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!emailRegex.test(email)) {
      toast.error("Please enter vaild email address");
      return;
    }
    if (!recaptchaResponse || recaptchaResponse.trim() === "") {
      toast.error("Please solve the captcha first");
      return;
    }
    const tid = toast.loading("Loading");

    axios
      .get(`https://famplan.co/mvp-api/api/task_management/schedule/${email}`)
      .then((response) => {
        if (response.status == 200) {
          toast.update(tid, {
            render: "Successfully signed up",
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeButton: true
          });
          setEmail("");
          recaptchaRef.current.reset();
          setRecaptchaResponse("");
          closeModel();
        } else {
          toast.update(tid, {
            render: "An error occured while signing up, try again later",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true
          });
        }
      })
      .catch((error) => {
        console.log("error occured ", error);
        toast.update(tid, {
          render: "An error occured while signing up, try again later",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          closeButton: true
        });
      }
      );
  };

  return createPortal(
    <>
      <div className="modal-backdrop"></div>
      <div className="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={closeModel}
              ></button>
            </div>
            <div className="modal-body">
              <div className="popup-content">
                <img src={img.popupImg} alt="header image" />
                <h5>Stay tuned!</h5>
                <p>
                  We are still perfecting our app for parents! Join us for beta
                  testing by providing your email here
                </p>
                <form
                  className="popup-sub-input-container"
                  onSubmit={handleSubmit}
                >
                  <input
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    type="text"
                    name="email"
                    placeholder="Enter your email here!"
                  />
                  <button type="submit">Submit</button>
                </form>
                <ReCAPTCHA
                  sitekey="6LeQ4ZonAAAAAMVVuDR-jkYeu7N11mlYA51EVsmH"
                  onChange={handleRecaptchaSuccess}
                  ref={recaptchaRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('modalPortal')
  )
};

export default Popup;
