import React, { useEffect, useState } from "react";
import "./CustomCalender.css";
import moment from 'moment';
const ScheduleGrid = ({ events }) => {
  // Sample data for days and timings (you can replace this with your actual data)
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const timings = [
    "6:00 AM",
    "7:00 AM",
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
  ];

  const [isHovered, setIsHovered] = useState({ row: null, col: null });
  const [isPopupVisible, setIsPopupVisible] = useState({
    row: null,
    col: null,
  });
  const [formatedTasks, setFormatedTasks] = useState({});
  const handleMouseEnter = (row, col) => {
    setIsHovered({ row, col });
  };

  const handleShowPopup = (row, col) => {
    if (isPopupVisible.row === row && isPopupVisible.col === col) {
      setIsPopupVisible({ row: null, col: null });
    } else {
      setIsPopupVisible({ row, col });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered();
  };

  useEffect(() => {
    const formatedEvents = {};
    Object.keys(events).forEach((date) => {
      let TimeObj = {
        "6:00 AM": [],
        "7:00 AM": [],
        "8:00 AM": [],
        "9:00 AM": [],
        "10:00 AM": [],
        "11:00 AM": [],
        "12:00 PM": [],
        "1:00 PM": [],
        "2:00 PM": [],
        "3:00 PM": [],
        "4:00 PM": [],
        "5:00 PM": [],
        "6:00 PM": [],
        "7:00 PM": [],
        "8:00 PM": [],
        "9:00 PM": [],
        "10:00 PM": [],
      };
      events[date].forEach((task) => {
        if (task && task.title.split(' ')[0]!='buffer') {
          let timeStr = `${task.formatedStartTime.split(":")[0]}:00 ${
            task.formatedStartTime.split(" ")[1]
          }`;
          TimeObj[timeStr].push(task);
        }
      });
      formatedEvents[date] = { ...TimeObj };
    });
    setFormatedTasks(formatedEvents);
  }, [events]);

  // Helper function to render the cells in the grid
  const renderCells = () => {
    return timings.map((timing, rowIndex) => (
      <tr key={timing}>
        <td className="timing-cell" style={{ border: "none" }}>
          {timing}
        </td>
        {Object.keys(formatedTasks).map((date, colIndex) => (
          <td key={date} className="day-cell" style={{ minHeight: "4rem" }}>
            {formatedTasks[date][timing][0] ? (
              <React.Fragment>
                <div
                  className="tasks"
                  onClick={() => {
                    handleShowPopup(rowIndex, colIndex);
                  }}
                  title={formatedTasks[date][timing][0]
                    ? formatedTasks[date][timing][0].title
                    : ""}
                >
                  <h4>
                    {formatedTasks[date][timing][0]
                      ? formatedTasks[date][timing][0].title
                      : ""}
                  </h4>
                  {formatedTasks[date][timing].length > 1 ? (
                    <h6>{`+${
                      formatedTasks[date][timing].length - 1
                    } tasks`}</h6>
                  ) : (
                    ""
                  )}
                </div>
                {formatedTasks[date][timing].length ? (
                  <div
                    className="onhover-popup"
                    style={{
                      display: `${
                        (isHovered &&
                          rowIndex === isHovered.row &&
                          colIndex === isHovered.col) ||
                        (isPopupVisible &&
                          rowIndex === isPopupVisible.row &&
                          colIndex === isPopupVisible.col)
                          ? "flex"
                          : "none"
                      }`,
                    }}
                  >
                    {formatedTasks[date][timing].map((task) => (
                      <div className="task" key={task.formatedStartTime}>
                        <div className="time">
                          {moment(task.formatedStartTime, "h:mm:ss A").format("h:mm A")} - {moment(task.formatedEndTime, "h:mm:ss A").format("h:mm A")}
                        </div>
                        <div className="heading">{task.title}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
             <div className="empty-div"></div>
            )}

            {/* You can render schedule items in each cell if needed */}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="schedule-grid">
      {Object.keys(formatedTasks).length ? (
        <table>
          <thead>
            <tr>
              <th></th>
              {Object.keys(formatedTasks).map((date) => (
                <th key={date}>{`${moment(date, "YYYY-MM-DD").format("dddd")}`} <h2 >{`${moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")}`}</h2> </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderCells()}</tbody>
        </table>
      ) : (
        "please Wait"
      )}
    </div>
  );
};

export default ScheduleGrid;
