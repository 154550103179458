import React from "react";
import "./try-now-button.css";

const TryNowButton = ({ onClick }) => {
  return (
    <button className="try-btn" onClick={onClick}>
      Try it Now
    </button>
  );
};

export default TryNowButton;
