import React from 'react'
import './Whoops.css'
import images from '../../assets/images'
import { useNavigate } from 'react-router-dom'
export default function Whoops({whoopsRef}) {
  const nevigate = useNavigate()
  return (
    <div className="whoops-cont">
      <div className="heading">Whoops..</div>
      <div className="sub-heading">
        we are currently perfecting the app. But you can trial our feature below
        and sign up to join our waitlist!
      </div>
      <div className="img-cont">
        <img src={images.working1} alt="" />
      </div>
      <div
        className="btn-cont"
        ref={whoopsRef}
        onClick={() => {
          window.scrollTo({
            top: 0,
          });
          nevigate("/", { state: { shouldScrollToTryOut: true }});
        }}
      >
        {" "}
        Sign Up Now{" "}
      </div>
    </div>
  );
}
