import demoLogo from "./images/demo.svg";
import logo from "./images/logo.png";
import phone from "./images/phone.svg";
import phone2 from "./images/phone2.svg";
import file from "./images/mingcute_file-security-fill.svg";
import stack from "./images/uim_layer-group.svg";
import calender from "./images/uim_schedule.svg";
import twoPhones from "./images/Mockup.svg";
import engage from "./images/engage.png";
import facebookLogo from "./images/facebook.svg";
import twiterLogo from "./images/Twitter.svg";
import instaLogo from "./images/instagram.svg";
import linkedinLogo from "./images/linkedin.svg";
import mealPlanner from "./images/mealPlanner.svg";
import taskManaging from "./images/taskManaging.svg";
import townHall from "./images/townhall.svg";
import houseHold from "./images/houseHold.png";
import nupurLeft from "./images/jemur.svg";
import nupurRight from "./images/nyemprot.svg";
import sketchCircle from "./images/Vector.svg";
import mainFlow from "./images/mainFlow.svg";
import ellipse1 from "./images/Ellipse1.svg";
import ellipse2 from "./images/Ellipse2.svg";
import closeModal from "./images/CloseModal.svg";
import logoL from "./images/logoL.png";
import modalBack from "./images/ModalBack.svg";
import welcomeToRooster from "./images/welcomeToRooster.gif";
import notes from "./images/notes.gif";
import minus from "./images/minu.svg";
import plus from "./images/plus.svg";
import closeOption from "./images/CloseOption.svg";
import chevronDown from "./images/Chevron_Down.svg";
import group26649 from "./images/Group26649.svg";
import arrowLeft from "./images/arrowLeft.svg";
import ellipse14 from "./images/Ellipse 14.svg";
import ellipse15 from "./images/Ellipse 15.svg";
import ellipse17 from "./images/Ellipse 17.svg";
import ellipse18 from "./images/Ellipse 18.svg";

import ellipse646 from "./images/Ellipse 646.svg";
import robot from "./images/Robot.svg";
import ellipse16 from "./images/Ellipse 16.svg";
import working1 from './images/Working 1.svg';
import phone1 from './images/phone1gif.gif'
import popupImg from "./images/popup-img.png";

import heroFamily from "./images/hero-family-1.png"
import heroWorkflow from "./images/hero-workflow-2.png"
import heroMeal from "./images/hero-meal-3.png"
import heroResourse from "./images/hero-resourse-4.png"

export default {
  ellipse17,
  phone1,
  working1,
  ellipse18,
  ellipse14,
  ellipse646,
  ellipse15,
  ellipse16,
  robot,
  demoLogo,
  arrowLeft,
  group26649,
  closeOption,
  minus,
  plus,
  notes,
  welcomeToRooster,
  modalBack,
  logoL,
  logo,
  phone,
  phone2,
  file,
  stack,
  calender,
  twoPhones,
  engage,
  facebookLogo,
  twiterLogo,
  instaLogo,
  linkedinLogo,
  mealPlanner,
  taskManaging,
  townHall,
  houseHold,
  nupurLeft,
  nupurRight,
  sketchCircle,
  mainFlow,
  ellipse1,
  ellipse2,
  closeModal,
  chevronDown,
  popupImg,
  heroFamily,
  heroWorkflow,
  heroMeal,
  heroResourse
};
