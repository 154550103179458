import React, { Component, useEffect, useRef, useState } from "react";
import "./LandingPage.css";
import DropdownList from "../../common/components/dropdown/DropdownList";
import images from "../../assets/images";
import LandingPagePopup from "../landing-page-popups/LandingPagePopup";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import TryNowButton from "../../common/components/try-now-button/try-now-button";

import { useContext } from "react"
import { PopupContext } from "../../contexts"
import { isIOS, isMacOs} from 'react-device-detect';

export default function LandingPage({
  whoopsRef,
  scrollToSection,
  fs4Ref,
}) {
  // hooks
  let [items, setItems] = useState([]);
  const [openAccordions, setOpenAccordions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const openModel = useContext(PopupContext);
  // handlers
  const handleOpen = () => {
    setShowPopup(true);
  };
  const handelAccordionOpen = (index) => {
    let temp = [];
    if (openAccordions.includes(index)) {
      temp = openAccordions.filter((i) => i != index);
    } else {
      temp = [...openAccordions, index];
    }
    setOpenAccordions(temp);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.shouldScrollToTryOut === true) {
      scrollToSection(fs4Ref);
      setTimeout(() => {
        setShowPopup(true);
      }, 800);
    }
  }, [location.state]);

  return (
    <div>
      <LandingPagePopup
        isOpen={showPopup}
        handleOpen={handleOpen}
        setIsOpen={setShowPopup}
      />
      <div className="fs1">
        <div className="heading" style={{ alignItems: "center" }}>
          <h1>Home Management, Modernized</h1>
          <p>
            Centrally manage your household tasks and resources with our
            innovative app-solution. Effortlessly simplify home operations with
            AI. Designed by busy parents for busy parents.
          </p>
        </div>
        <div className="cards">
          <div className="card">
            <img src={images.heroResourse} alt="hero img 01" />
            <h6>Household</h6>
          </div>

          <div className="card">
            <img src={images.heroMeal} alt="hero img 02" />
            <h6>Meal planner</h6>
          </div>

          <div className="card">
            <img src={images.heroWorkflow} alt="hero img 03" />
            <h6>Task managing</h6>
          </div>

          <div className="card">
            <img src={images.heroFamily} alt="hero img 04" />
            <h6>Townhall</h6>
          </div>
        </div>
      </div>
      <div className="fs2">
        <div className="left">
          <h2>Manage your home with less stress.</h2>
          <p>
            Let us automate your home operations, ensuring tasks and resources
            are effectively allocated.
          </p>
        </div>
        <div className="right">
          <div className="card">
            <div className="date-part">
              <h4>17 Aug</h4>
              <p>8:00 AM</p>
            </div>
            <div className="content">
              <div className="radio-btn"></div>
              <div className="task-info">
                <h4>Prepare daypack for zoo trip</h4>
                <p>Assigned to John</p>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="date-part">
              <h4>17 Aug</h4>
              <p>9:00 AM</p>
            </div>
            <div className="content">
              <div className="radio-btn"></div>
              <div className="task-info">
                <h4>Laundry duty</h4>
                <p>Assigned to John</p>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="date-part">
              <h4>17 Aug</h4>
              <p>10:00 AM</p>
            </div>
            <div className="content">
              <div className="radio-btn"></div>
              <div className="task-info">
                <h4>Change bedsheets</h4>
                <p>Assigned to John</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section2">
        <div className="container">
          <div className="img-container-background">
            <div className="ellipse15">
              <img src={images.ellipse15} alt="" />
            </div>
            <div className="ellipse16">
              <img src={images.ellipse16} alt="" />
            </div>

            <div className="ellipse">
              <img src={images.ellipse646} className="ellipse" alt="" />
            </div>
            <h1>Manage your household centrally, with AI</h1>
            <div className="features">
              <div className="feature">
                <div className="icon-container">
                  <img src={images.robot} alt="calender" />
                </div>
                <h4>Powered by AI</h4>
                <p>
                  Unleash the boundless potential of AI right at your
                  fingertips, harmoniously integrated into your home.
                </p>
              </div>
              <div className="feature">
                <div className="icon-container">
                  <img src={images.stack} alt="calender" />
                </div>
                <h4>Home Operations Simplified</h4>
                <p>
                  Centrally manage your home, from domestic helpers’ tasks, to
                  seamless meal planning, and well-being for the family.
                </p>
              </div>
              <div className="feature">
                <div className="icon-container">
                  <img src={images.file} alt="calender" />
                </div>
                <h4>Family Privacy</h4>
                <p>
                  Your data, stored locally and strictly confidential. We limit
                  the use of your data to improve user experience{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fs3">
        <img src={images.nupurLeft} alt="" className="side-images" />
        <div className="center-image">
          <h5>Generate chores schedule below!</h5>
          <img src={images.sketchCircle} alt="" />
        </div>
        <img src={images.nupurRight} alt="" className="side-images" />
      </div>
      <div className="fs4">
        <div className="left">
          <div className="box">
            <img src={images.phone1} className="phone1" alt="" />
            <div className="reactangle"></div>
            <img src={images.ellipse1} className="ellipse1" alt="" />
            <img src={images.ellipse2} className="ellipse2" alt="" />
          </div>
        </div>

        <div className="right">
          <div className="content" ref={fs4Ref}>
            <h2>Let’s get started.</h2>
            <p>
              Here is a sneak preview of our tailored solutions based on your
              (yes, you!) lifestyle. Click below for a glance at your home
              operations with elevated home coordination and management.
            </p>
          </div>
          <button
            className="try-it-out-btn"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowPopup(true);
            }}
          >
            Try It Out!
          </button>
        </div>
      </div>

      <div className="section4">
        <div className="center-div">
          <div className="left">
            <img className="left-img" src={images.engage} alt="" />
          </div>
          <div className="right">
            <h4>Manage your home with AI today.</h4>
            <p>
              Automate, centralize, and simplify. Spend less time managing your
              home, and more time with your loved ones.
            </p>
            <div
              className="download-button"
              onClick={() => {
                if(isIOS || isMacOs){
                  return window.open("https://apps.apple.com/de/app/famplan-plus/id6471747319?l=en-GB", "_blank");
                } 
                return window.open("https://play.google.com/store/apps/details?id=com.aviary.rooster", "_blank");
              }}
              style={{ cursor: "pointer" }}
            >
              <span>Download now</span>{" "}
              {/* <img
                src={images.arrowLeft}
                alt=""
                style={{ paddingLeft: "1rem" }}
              /> */}
            </div>
          </div>
        </div>
      </div>
      {!showPopup && (<TryNowButton
        onClick={() => {
          setShowPopup(true);
        }}
      />)}
    </div>
  );
}
