// Style //
import { useState, useRef, useEffect } from "react";
import "./DropdownList.css";


function DropdownList({ listItems, title ,handelOnClick }) {

  const dropdownRef = useRef()

  const [isChecked,setIsChecked] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsChecked(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  const optionsHendler = (item)=>{
    handelOnClick(item)
    setIsChecked(false)
  }

  return (
    <div className={`${isChecked? 'open': ''} seasons-dropdown_cont`} ref={dropdownRef}>
      <div className="sec-center">
        <button type="button" className="dropdown-btn" onClick={()=>setIsChecked(!isChecked)}>
          <span>{title}</span>
          <i className="uil  fa-solid fa-chevron-down"></i>
        </button>
        <div className="section-dropdown" >
          <div className="link_cont">
            {listItems.map((item, index) => (
              <div className="options" key={item.id} onClick={()=>optionsHendler(item) } data-toggle="tooltip" data-placement="top" title={item.item1 ? item.item1 : '-'}>
                {item.item1 ? item.item1 : '-'}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DropdownList;
