// Router //
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useState } from 'react';
import { PopupContext } from "./contexts"

import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./pages/landing-page/LandingPage";
import Navbar from "./common/components/navbar/Navbar";
import Footer from "./common/components/footer/Footer";
import FeaturePage from "./pages/feature-page/FeaturePage";
import LandingPagePopup from "./pages/landing-page-popups/LandingPagePopup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import ScheduleGrid from "./pages/landing-page-popups/CustomCalender";
import { useRef } from "react";
import Whoops from "./pages/whoops-page/Whoops";
import TermsOfService from "./pages/terms-of-service/terms-of-service";
import Popup from "./common/components/popup/Popup";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";

function App() {
  let fs4Ref = useRef(null);
  let whoopsRef = useRef(null);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const openModel = () => setPopupOpen(true);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start", // Adjust this if you want to change the scrolling position within the section
    });
  };
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        toastClassName="custom-toast-container"
      />
      <Popup open={isPopupOpen} closeModel={() => setPopupOpen(false)} />
      <PopupContext.Provider value={openModel}>
        <BrowserRouter>
          <Navbar
            scrollToSection={scrollToSection}
            fs4Ref={fs4Ref}
            whoopsRef={whoopsRef}
          />
          <div>
            <Routes>
              <Route path="/">
                <Route
                  path="/"
                  element={
                    <LandingPage
                      fs4Ref={fs4Ref}
                      scrollToSection={scrollToSection}
                      whoopsRef={whoopsRef}
                    />
                  }
                />
                <Route path="/features" element={<FeaturePage />} />
                <Route path="/popup" element={<LandingPagePopup />} />
                <Route path="/termsOfService" element={<TermsOfService />} />
                <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                {/*<Route path='/custom' element={<ScheduleGrid />} />*/}
                <Route
                  path="/whoops"
                  element={<Whoops whoopsRef={whoopsRef} />}
                />
              </Route>
              {/*<Route path='/score-sheet' element={<ScoreSheet />} />*/}
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </PopupContext.Provider>
    </div>
  );
}

export default App;
