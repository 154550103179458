import React, { useState } from "react";
import images from "../../../assets/images";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="footer">
        <img src={images.ellipse17} className="ellipse17" alt="" />
        <img src={images.ellipse18} className="ellipse18" alt="" />

        <div className="tab1">
          <img src={images.logo} alt="" />
          <h3>Famplan</h3>
        </div>
        <div className="tab3">
          <p>
            Famplan: Your Ultimate Household Task Manager. Simplify your
            household operations all within your fingertips.
          </p>
        </div>
        {/*======= contribution by @tanveerAnjum ======= */}
        {/* <form className="footer-news-input-container" onSubmit={handleSubmit}>
          <input
            type="text"
            name="email"
            placeholder="Enter your email here!"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <button type="submit">Join Us</button>
        </form> */}
        {/* ============================================ */}
        <p
          className="contact-email-p"
          onClick={() => {
            window.location.href = "mailto:hello@famplan.co";
          }}
        >
          {" "}
          <span className="contact-email-title">Contact us: </span>
          <span className="contact-email">hello@famplan.co</span>
        </p>
        <div className="tab4">
          <img src={images.facebookLogo} alt="facebookLogo" />
          <img src={images.twiterLogo} alt="twiterLogo" />
          <img src={images.linkedinLogo} alt="linkedinLogo" />
          <img src={images.instaLogo} alt="instaLogo" />
        </div>
        <div className="terms-rights-container">
          <p>© famplan 2024 All rights reserved.</p>
          <div className="terms">
            <p
              onClick={() => {
                window.location.href = "/privacyPolicy";
              }}
            >
              Privacy Policy
            </p>
            <p
              onClick={() => {
                window.location.href = "/termsOfService";
              }}
            >
              Terms of Service
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
