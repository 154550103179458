import React, { useState } from "react";
import images from "../../../assets/images";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";

import { useContext } from "react";
import { PopupContext } from "../../../contexts";

import { isIOS, isMacOs } from "react-device-detect";

export default function Navbar({ scrollToSection, fs4Ref, whoopsRef }) {
  const [seeSection, setSeeSection] = useState(false);
  const href = window.location.href.split("/").reverse()[0];
  const navigate = useNavigate();
  const openModel = useContext(PopupContext);
  return (
    <nav>
      <div className="nav_cont">
        <ul className="links">
          <li className="link">
            <div className="logo_cont">
              <img src={images.logo} alt="" />
            </div>
          </li>
          <li
            className="link"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </li>
          {href != "whoops" && href != "termsOfService" ? (
            <li
              className="link"
              onClick={() => {
                scrollToSection(fs4Ref);
              }}
            >
              Free trial
            </li>
          ) : (
            ""
          )}
        </ul>
        {href != "whoops" ? (
          <div className="btn-cont">
            <button
              className="download_app_btn"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (isIOS || isMacOs) {
                  return window.open(
                    "https://apps.apple.com/de/app/famplan-plus/id6471747319?l=en-GB",
                    "_blank"
                  );
                }
                return (
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.aviary.rooster",
                    "_blank"
                  )
                );
              }}
            >
              Download now
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="mobile-nav">
        <div className="logo_cont">
          <img src={images.logo} alt="" />
        </div>
        <a
          class="navbar-icon"
          onClick={() => {
            setSeeSection(!seeSection);
          }}
        >
          {seeSection ? (
            <i class="fas fa-times" style={{ cursor: "pointer" }}></i>
          ) : (
            <i class="fas fa-bars" style={{ cursor: "pointer" }}></i>
          )}
        </a>
      </div>
      <div className="mobile-nav2" style={{ visibility: "hidden" }}>
        <div className="logo_cont">
          <img src={images.logo} alt="" />
        </div>
        <a
          class="navbar-icon"
          onClick={() => {
            setSeeSection(!seeSection);
          }}
        >
          {seeSection ? (
            <i class="fas fa-times" style={{ cursor: "pointer" }}></i>
          ) : (
            <i class="fas fa-bars" style={{ cursor: "pointer" }}></i>
          )}
        </a>
      </div>
      <div
        className="mobile-nav-section"
        style={{ display: seeSection ? "flex" : "none" }}
      >
        <ul className="links">
          <li
            className="link"
            onClick={() => {
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            Home
          </li>
          {href != "whoops" ? (
            <React.Fragment>
              {href !== "termsOfService" && (
                <li
                  className="link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    scrollToSection(fs4Ref);
                  }}
                >
                  Free trial
                </li>
              )}
              <div
                className="link"
                onClick={() => {
                  // navigate("/whoops");
                }}
              >
                <button
                  className="download_app_btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (isIOS || isMacOs) {
                      return window.open(
                        "https://apps.apple.com/de/app/famplan-plus/id6471747319?l=en-GB",
                        "_blank"
                      );
                    }
                    return window.open(
                        "https://play.google.com/store/apps/details?id=com.aviary.rooster",
                        "_blank"
                      );
                  }}
                >
                  Download now
                </button>
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </ul>
      </div>
    </nav>
  );
}
